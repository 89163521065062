import React, { Component } from 'react'
import AuthContext from '../../context/auth-context'
import { Container } from './style'
const logo = require('../../assets/images/general/logo-bw.svg')

class NotAllowed extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Container>
        <div className="content">
          <div className="line">
            <img src={logo} alt="Grande table" />
          </div>
          <p className="title">Estamos en mantenimiento</p>
          <p className="subtitle">
            Estamos trabajando para mejorar Grande Table. <br />
            Volveremos lo antes posible
          </p>
        </div>
      </Container>
    )
  }
}
NotAllowed.contextType = AuthContext

export default NotAllowed
