import media from '../../styles/media'
import styled from 'styled-components'
import background from '../../assets/images/pages/bg-maintenance-blur.jpg'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  .blur {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    filter: blur(2px);
  }
  .content {
    font-family: 'Roboto';
    z-index: 3;
    width: 86%;
    height: auto;
    text-align: center;
    background-color: rgb(0, 0, 0);
    border: 1px solid #c59d5f;
    color: white;
    padding: 45px 0 50px 0;
    border-radius: 8px;
    ${media.sm`
      width: 40%;
    `};
    .title {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.38px;
    }

    .line {
      border-bottom: 2px solid #c59d5f;
      margin: auto auto 28px;
      width: max-content;
      padding-bottom: 30px;
      img {
        width: 100px;
      }
    }

    .subtitle {
      font-family: 'Roboto';
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.08px;
    }
  }
`
